<template>
  <div class="container mt-4">
    <div class="row" v-if="getAllProfilePages.length > 0">
      <div
        v-for="(partner, index) in getAllProfilePages"
        :key="index"
        class="col-md-4 mb-4"
      >
        <div class="card shadow-sm">
          <!-- HTML Preview Using iframe -->
          <iframe
            :src="partner?.link_to_doc"
            title="Preview"
            class="card-img-top"
            scrolling="no"
            style="height: 200px; width: 100%; border: none"
          >
          </iframe>

          <!-- Card Body with Copy Button at Bottom -->
          <div class="card-body d-flex flex-column justify-content-between h-100 text-center">
            <h5 class="card-title text-primary font-weight-bold">
              {{ partner?.organisation_name }}
            </h5>

            <!-- Copy Button at Bottom -->
            <button
              class="btn btn-primary btn-sm mt-auto"
              @click="copyToClipboard(partner?.web_url)"
            >
              <i class="fas fa-copy"></i> Copy URL
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h3 class="text-center text-muted">No profiles found.</h3>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { generateLocalFileUrl } from '../../helpers/helper.js'

export default {
  data() {
    return {
      generateLocalFileUrl
    };
  },
  computed: {
    ...mapGetters(["getAllProfilePages"]),
  },
  methods: {
    ...mapActions(["showToast", "fetchAllProfilePages"]),
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.showToast({
            class: "bg-success text-white",
            message: "Profile URL Copied!",
          });
        })
        .catch((err) => {
          console.error("Failed to copy:", err);
        });
    },
  },
  async mounted() {
    await this.fetchAllProfilePages();
  },
};
</script>

<style scoped>
.card {
  border-radius: 8px;
  overflow: hidden;
  height: 350px;
  display: flex;
  flex-direction: column;
}

.card-img-top {
  height: 200px;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.btn {
  width: 100%;
}
</style>
